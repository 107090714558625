
export class FollowUp {
    constructor(aFollowUpDate: Date, aIsComplete: boolean) {
        this.followUpDate = aFollowUpDate;
        this.isComplete = aIsComplete;
        this.isCompleteStr = "No";
        this.completionDate = null;
        this.ID = '';
        this.avatarThemeColor = 'tertiary';
    }   
    
    ID: string;
    followUpDate : Date;
    isComplete: boolean;
    isCompleteStr: string;
    completionDate: Date | null;
    avatarThemeColor: string;
}

export default FollowUp;

