import { Logger } from 'aws-amplify'

const logger = new Logger('DateHelper', 'INFO');

export function getNumberOfDays(startDate: Date, endDate: Date) {
    logger.debug("getNumberOfDays | 1.0");
    logger.debug("getNumberOfDays | 1.1 - startDate - " + startDate);
    logger.debug("getNumberOfDays | 1.2 - endDate - " + endDate);

    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();
    logger.debug("getDateDiff | 1.4 - diffInTime - " + diffInTime);

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay); 

    logger.debug("getDateDiff | 1.5 - diffInDays - " + diffInDays);
    return diffInDays;
}

export function GetFormattedTodayDateStringStdA(){
    let today = new Date();
    return today.getFullYear() +'/'+ (today.getMonth()+1)+'/'+today.getDate() 
}

export function GetFormattedDateTodayStringStdB(){
    let today = new Date();
    return (today.getMonth()+1)+'/'+today.getDate()+'/'+today.getFullYear();
}

export function GetFormattedDateStringStdA(aDate: Date){
    return aDate.getFullYear() +'/'+ (aDate.getMonth()+1)+'/'+aDate.getDate() 
}

export function GetFormattedDateStdA(aDate: Date){
    let dateStr = aDate.getFullYear() +'/'+ (aDate.getMonth()+1)+'/'+aDate.getDate()
    let newDate = new Date( dateStr )
    return newDate
}

export function GetFormattedDateFromStrStdA(aDate: string){
    let oldDate = new Date( Date.parse(aDate));
    //let oldDateStr = oldDate.getFullYear() +'/'+ (oldDate.getMonth()+1)+'/'+oldDate.getDate()
    //let newDate = new Date( oldDateStr )
    return oldDate
}

export function GetFormattedDateStringFromStrStdA(aDate: string){
    let mvDate = new Date( Date.parse(aDate));
    //let oldDateStr = mvDate.getFullYear() +'/'+ (mvDate.getMonth()+1)+'/'+mvDate.getDate()
    return mvDate.getFullYear() +'/'+ (mvDate.getMonth()+1)+'/'+mvDate.getDate()
}

export function GetFormattedDateStringStdB(aDate: Date){
    return (aDate.getMonth()+1)+'/'+aDate.getDate()+'/'+aDate.getFullYear();
}

export function GetCurrentDateWithDayOfWeek(){
    let today = new Date();
    let weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    let dayName = weekday[today.getDay()];
    return dayName +', '+(today.getMonth()+1)+'/'+today.getDate()+'/'+today.getFullYear()
}

export function GetCEDisplayDate(aDate: Date) {
    let mvDate = new Date(aDate);
    const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let formatted_date = mvDate.getDate() + "-" + months[mvDate.getMonth()] + "-" + mvDate.getFullYear();
    return formatted_date;

}

export function DetermineDateIsCurrentMonth(aDateStr: string) {

    // remanining days in current month and today. Use > if you don't need today.
    let mvDateNow = new Date();
    var mvDateToCheck = new Date( Date.parse(aDateStr))
    if (
        (mvDateToCheck.getFullYear() == mvDateNow.getFullYear()) &&
        (mvDateToCheck.getMonth() == mvDateNow.getMonth())
    ) {
        return true;
    } else {
        return false;
    }
    
    /*
    // remanining days in current month and today. Use > if you don't need today.
    let mvDateNow = new Date();
    var mvDateToCheck = new Date( Date.parse(aDateStr))
    if (
        (mvDateToCheck.getFullYear() == mvDateNow.getFullYear()) &&
        (mvDateToCheck.getMonth() == mvDateNow.getMonth()) &&
        (mvDateToCheck.getDate() >= mvDateNow.getDate())
    ) {

    }

    // any day in next month. Doesn't include current month remaining days.
    var nextMonth = mvDateNow.getMonth() + 1
    var nextYear = mvDateNow.getFullYear()
    if (nextMonth == 12) {
        nextMonth = 0
        nextYear++
    }

    if (
        (mvDateToCheck.getFullYear() == nextYear) &&
        (mvDateToCheck.getMonth() == nextMonth)
    ) {
    
    }
    */

}