import * as React from 'react';
import { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from './../UserContext';
import {
    StackLayout,
    Avatar,
    Card,
    CardHeader,
    CardTitle,
    CardSubtitle,
    CardImage,
    CardBody,
    CardActions,
  } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { v4 as uuidv4 } from 'uuid';

import {queryEncountersForUser, updateFollowUpComplete} from '../utils/DBHelper';
import Encounter from '../classes/Encounter';


import { Logger } from 'aws-amplify';

const logger = new Logger('FollowUpCards', 'INFO');

interface IFollowUpCardsProps {
    encWFUDataProp: Encounter[];
    qryEncProp: () => void;
}

const FollowUpCards = ({encWFUDataProp, qryEncProp } : IFollowUpCardsProps) => {

    const [encWFUData, setEncWFUData] = useState<Encounter[]>(encWFUDataProp); //This is Encounters with FollowUps
    const [isEmpty, setIsEmpty] = useState(false);

    const normalAvatar = <Avatar type="icon" themeColor={"tertiary"}><span className="k-icon k-i-notification" /></Avatar>;
    const errorAvatar = <Avatar type="icon" themeColor={"error"}><span className="k-icon k-i-notification" /></Avatar>;

    useEffect(() => {
        logger.debug("useEffect | 1.0");
        const count = encWFUDataProp.length;
        logger.debug("useEffect | 1.1 - count - " + count);
        if (count < 1) {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }
        if (encWFUDataProp) {
            logger.debug("useEffect | 1.2");
            setEncWFUData(encWFUDataProp);
        }
        }, [encWFUDataProp])      

    const followUpCompleted = (event: React.MouseEvent<HTMLButtonElement>) => {
        logger.debug("followUpCompleted | 1.0");
        const fID = event.currentTarget.getAttribute('follow-up-id');
        logger.debug("followUpCompleted | 1.1 - event - " + event.currentTarget.name);
        if (fID !== null) {
            logger.debug("followUpCompleted | 1.2 - fID - " + fID);
            updateFollowUp(fID).then(fuNdx => {
                logger.debug("followUpCompleted | 1.3 - fuNdx - " + fuNdx);
                if (fuNdx !== undefined ) {
                    logger.debug("followUpCompleted | 1.4 - fuNdx - " + fuNdx);
                    //const newEncounters = encWFUData.splice(fuNdx, 1);
                    //const newObject = [...newEncounters];
                    qryEncProp();
                }
            })
            
        }

    };

    async function updateFollowUp(pFollowUpID: string) {
        logger.debug("updateFollowUp | 1.0 - pFollowUpID - " + pFollowUpID);
        let fuNdx = -1;
        await updateFollowUpComplete(pFollowUpID).then( result => {
            logger.debug("updateFollowUp | 2.0 - result - " + JSON.stringify(result));
            //window.location.reload();  //Hack to refresh react component

            //let fuNdx = -1; // Set a default if we don't find it
            for (let i = 0; i < encWFUData.length; i++) {     
                logger.debug("updateFollowUp | 2.1 - index - " + i); 
                 // I'm looking for the index i, when the condition is true
                 if (encWFUData[i].followUp !== undefined) {
                    logger.debug("updateFollowUp | 2.2"); 
                    if (encWFUData[i].followUp?.ID === pFollowUpID) {  
                        logger.debug("updateFollowUp | 2.3 - encounters[i].followUp?.ID - " + encWFUData[i].followUp?.ID); 
                        fuNdx = i;
                        break;
                        //setEncounters(encounters.splice(fuNdx, 1)); //remove the Encounter and FollowUp where the FollowUp has been marked Complete
                        //break;       // <=== breaks out of the loop early
                    }
                 }

            }
            logger.debug("updateFollowUp | 4.0 - ndx - " + fuNdx);
            
        }).catch( reason => {
            logger.debug("updateFollowUp | 5.0 - error reason - " + reason);    
        });
        logger.debug("updateFollowUp | 6.0 - ndx - " + fuNdx);
        return fuNdx;        
    } 

    switch (isEmpty) {
        case false:
            return (
                
                <React.Fragment>
                { encWFUData.map((encounter,id):React.ReactNode => (

                    <div key={uuidv4()}>
                        <Card className="k-flex-30" 
                            key={uuidv4()}
                            style={{
                                width: 240,
                                boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                                marginTop: "10px",
                                marginRight: "5px",
                            }}>
                            <CardHeader className="k-hbox">

                                {
                                    encounter.followUp?.avatarThemeColor === 'error'
                                    ? errorAvatar
                                    : encounter.followUp?.avatarThemeColor === 'tertiary'
                                    ? normalAvatar
                                    : normalAvatar
                                }

                                <div>
                                <CardTitle>{encounter.followUp?.followUpDate.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric' })}</CardTitle>
                                <CardSubtitle>{encounter.eType}</CardSubtitle>
                                </div>
                            </CardHeader>
                            <CardImage className="k-card-cover" src={""} />
                            <CardBody>
                                <p className="photo-text">Encounter Date: {encounter.eDate.toLocaleDateString("en-US", { weekday: 'short', year: '2-digit', month: 'long', day: 'numeric' })}</p>
                                <p className="photo-text">Encounter Time: {encounter.eTime.toLocaleTimeString("en-US", { hour: 'numeric', minute: '2-digit', hourCycle: 'h24'})}</p>
                            </CardBody>
                            <CardActions
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >

                            <div 
                                className="k-form-buttons"
                            >

                                <Button 
                                    icon="paste" 
                                    title="Complete"
                                    type = "button"
                                    className = "btn submit-btn"
                                    onClick = {followUpCompleted}
                                    name = "submit-btn"
                                    follow-up-id = {encounter.followUp?.ID}
                                >
                                    Complete
                                </Button>
                            </div>

                            </CardActions>
                        </Card>
                <style>{`
                .k-form-buttons {
                    display: block;
                    margin: auto;
                }
                `}</style>             
                    </div>     
                ))}
                </React.Fragment>  

            )
        case true:
            return (
            <React.Fragment>
                <div key={uuidv4()}>
                    <Card className="k-flex-30" 
                        key={uuidv4()}
                        style={{
                            width: 240,
                            boxShadow: "0 0 4px 0 rgba(0, 0, 0, .1)",
                            marginTop: "10px",
                            marginRight: "5px",
                        }}>
                        <CardHeader className="k-hbox">
                            <Avatar type="icon" themeColor={"success"}><span className="k-icon k-i-check-circle" /></Avatar>
                            <div>
                                <CardTitle>Good Job</CardTitle>
                                <CardSubtitle>No Follow-Ups</CardSubtitle>
                            </div>
                        </CardHeader>
                        <CardImage className="k-card-cover" src={""} />
                        <CardBody>
                        </CardBody>
                        <CardActions
                            style={{ display: "flex", justifyContent: "space-between" }}
                        >

                        </CardActions>
                    </Card>
            <style>{`
            .k-form-buttons {
                display: block;
                margin: auto;
            }
            `}</style>             
                </div>     
            </React.Fragment> 
        )
    }
}

export default FollowUpCards;