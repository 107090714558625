import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { Logger } from 'aws-amplify';

import Button from '@mui/material/Button';
import BorderClearOutlinedIcon from '@mui/icons-material/BorderClearOutlined';
import SendIcon from '@mui/icons-material/Send';
import { DatePicker, TimePicker, DatePickerChangeEvent, TimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { RadioGroup as KRadioGroup, RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import { UserContext } from '../UserContext';
import {hospitals} from '../resources/hospitals';
import {clinics} from '../resources/clinics';
import {states} from '../resources/states';
import {units} from '../resources/units';
import {specialties} from '../resources/specialties';
import {psas} from '../resources/psas';
import Encounter from '../classes/Encounter';
import {createEncounterAndFollowUp} from '../utils/DBHelper';
import {stringToBoolean} from '../utils/TypeHelper';
import NNLoader from '../components/NNLoader';
import { width } from '@mui/system';

const logger = new Logger('Encounters', 'INFO');

const Encounters = () => {

    const [eDate, setEdate] = React.useState(new Date());
    const [eTime, setEtime] = React.useState<Date>(new Date(),);
    //const [loc1, setLoc1] = React.useState<null | string>(null);
    /*
    const [loc1, setLoc1] = React.useState<{value: {text:null | string, id:null | string}}>(
        { 
            value: { text: null, id: null }, 
        }

    );
    */
    const [loc1, setLoc1] = React.useState<{text:null | string, id:null | string}>(
        { 
            text: null, id: null, 
        }

    );    
    const [loc2, setLoc2] = React.useState<{text:null | string, id:null | string}>(
        { 
            text: null, id: null, 
        }

    );    
    const [loc3, setLoc3] = React.useState<{text:null | string, id:null | string}>(
        { 
            text: null, id: null, 
        }

    );    

    const [eType, setEtype] = React.useState('');
    const [eReason, setEreason] = React.useState('');
    const [eRole, setErole] = React.useState('');
    const [eFollowup, setEfollowup] = React.useState('');

    const [loc2vis, setLoc2vis] = React.useState(false);
    const [loc3vis, setLoc3vis] = React.useState(false);
    const [afterlocVis, setAfterLocVis] = React.useState(false);
    const [submissionError, setSubmissionError] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    //label name for the next dropdown past Loc1
    const [loc2lbl, setLoc2lbl] = React.useState('');
    //label name for the next dropdown past Loc2
    const [loc3lbl, setLoc3lbl] = React.useState('');

    //window size change state - min width est by tailwind
    const [isDesktop, setDesktop] = useState(window.innerWidth > 639);
 
    const updateMedia = () => {
        setDesktop(window.innerWidth > 639);
      };

    useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
    }); 

    const user = useContext(UserContext);

    //https://reactjs.org/docs/forms.html
    const handleDateChange = (event: DatePickerChangeEvent) => {
        logger.debug("handleDateChange | 1.0");
        const tgtName = event.target.name;
        const val = event.target.value;
        logger.log("handleDateChange | 2.0 - event.target.name - " + tgtName)
        if (val != null) {
            if (tgtName === 'enc-date-picker'){
                setEdate(val);
            } 
        }    
    }

    const handleTimeChange = (event: TimePickerChangeEvent) => {
        logger.debug("handleTimeChange | 1.0");
        const tgtName = event.target.name;
        const val = event.target.value;
        logger.log("handleTimeChange | 2.0 - event.target.name - " + tgtName)
        if (val != null) {
            if (tgtName === 'enc-time-picker'){
                setEtime(val);  
            } 
        }    
    }

    const handleClick = (btntype: string) => {
        let fvButtonType = btntype;
        logger.debug("handleClick | 1.0 - event type - " + fvButtonType);
        
        //Validate that all the required questions have an answer
        if (fvButtonType === 'Submit') {
            
            let errFlag = 0;
            if (eDate === null) {
                logger.debug("handleClick | 2.0");
                errFlag = errFlag+1;
            } 
            if (eTime === null) {
                logger.debug("handleClick | 2.1");
                errFlag = errFlag+1;
            }
            if (loc1 === null) {
                logger.debug("handleClick | 2.2");
                errFlag = errFlag+1;
            }
            if (loc2 === null) {
                logger.debug("handleClick | 2.3");
                errFlag = errFlag+1;
            }
            if (loc3 === null) {
                logger.debug("handleClick | 2.4");
                errFlag = errFlag+1;
            }
            if (eType === '' || eType === null) {
                logger.debug("handleClick | 2.5");
                errFlag = errFlag+1;
            }                        
            if (eReason === '' || eReason === null) {
                logger.debug("handleClick | 2.6");
                errFlag = errFlag+1;
            }
            if (eRole === '' || eRole === null) {
                logger.debug("handleClick | 2.7");
                errFlag = errFlag+1;
            }
            if (eFollowup === '' || eFollowup === null) {
                logger.debug("handleClick | 2.8");
                errFlag = errFlag+1;
            }
            if (errFlag > 0) {  
                logger.debug("handleClick | 3.0 - errFlag - " + errFlag);
                setSubmissionError(true);
            } else {
                logger.debug("handleClick | 3.5 - errFlag - " + errFlag);
                setSubmissionError(false);
                let newEncounterObj: Encounter = createEncounterObject();
                setIsLoading(true);
                createEncounter(newEncounterObj);
                logger.debug("handleClick | 4.0 - encounter - " + newEncounterObj.userName);
            }
        }
        if (fvButtonType === 'Clear'){
            clearForm();                 
        }
    }

    const clearForm = () => {
        setEdate( new Date() );
        setEtime(new Date() );
        //setLoc1({value: {text:null,id:null}});
        setLoc1({text:null,id:null});
        setLoc2({text:null,id:null});
        setLoc3({text:null,id:null});
        setEtype('');
        setEreason('');
        setErole('');
        setEfollowup(''); 
        setLoc2vis(false);
        setLoc3vis(false);
        setAfterLocVis(false);
        setSubmissionError(false); 
    }

    async function createEncounter(aEncObj:Encounter) {
        logger.debug("createEncounter | 1.0 - aEncObj - " + aEncObj.userName);
        
        await createEncounterAndFollowUp(aEncObj).then( result => {
            logger.debug("createEncounter | 2.0 - result - " + JSON.stringify(result));
            clearForm();
            setIsLoading(false);
          }).catch( reason => {
            logger.debug("createEncounter | 3.0 - error reason - " + reason);    
            setSubmissionError(true);
        });        
    }

    const createEncounterObject = () => {
        logger.debug("createEncounterObject | 1.0 - user.email - " + user.email);
        logger.debug("createEncounterObject | 1.1 - eFollowup - " + eFollowup);
        logger.debug("createEncounterObject | 1.2 - loc1 - " + JSON.stringify(loc1));
        logger.debug("createEncounterObject | 1.3 - loc1.text - " + loc1.text);
        //let loc1obj = JSON.parse(JSON.stringify(loc1));
        //logger.debug("createEncounterObject | 1.4 - loc1obj.text - " + loc1obj.text);

       

        let newEncounterObj = new Encounter(
            user.email,
            eDate,
            eTime,
            loc1.text!,
            loc2.text!,
            loc3.text!,
            eType,
            eReason,
            eRole,
            stringToBoolean(eFollowup)
        )
        logger.debug("createEncounterObject | 3.0 - newEncounterObj.eFollowup - " + newEncounterObj.eFollowUp);
       return newEncounterObj;     
    }

    const handleKRGChange = (event: RadioGroupChangeEvent) => {
        let fvEventType = event.syntheticEvent.type; //this works
        let fvTgtName = event.nativeEvent.target.name; //this works
        let fvTgtVal = event.value; // this works
        
        logger.debug("handleKRGChange | 1.0 - event type - " + fvEventType);
        logger.debug("handleKRGChange | 1.1 - fvTgtName - " + fvTgtName);
        logger.debug("handleKRGChange | 1.2 - fvTgtVal - " + fvTgtVal);

        if (fvTgtName === 'encounter-type-rbg') {
            let fvTgtVal4 = fvTgtVal as string;
            setEtype(fvTgtVal4);
        } else if (fvTgtName === 'encounter-reason-rbg') {
            let fvTgtVal5 = fvTgtVal as string;
            setEreason(fvTgtVal5);
        } else if (fvTgtName === 'encounter-role-rbg') {
            let fvTgtVal6 = fvTgtVal as string;
            setErole(fvTgtVal6);
        } else if (fvTgtName === 'encounter-followup-rbg') {
            let fvTgtVal7 = fvTgtVal as string;
            logger.debug("handleKRGChange | 3.0 - fvTgtVal7 - " + fvTgtVal7);
            setEfollowup(fvTgtVal7);
        }
    }

    const handleKDDChange = (event: DropDownListChangeEvent) => {
        let fvEventType = event.syntheticEvent.type; //this works
        let fvTgtName = event.target.name; //this works
        let fvTgtVal = event.target.value.text; // this works
        let fvTgtValId = event.target.value.id;
        logger.debug("handleKDDChange | 1.0 - event type - " + fvEventType);
        logger.debug("handleKDDChange | 1.1 - fvTgtName - " + fvTgtName);
        logger.debug("handleKDDChange | 1.2 - fvTgtVal - " + fvTgtVal);   
        logger.debug("handleKDDChange | 1.3 - fvTgtValId - " + fvTgtValId); 
        //loc1 - inpatient, outpatient, corporate, etc.
        if (fvTgtName === 'loc1-select') {
            let fvTgtVal1 = fvTgtVal as string;
            setLoc1(event.target.value);
            setLoc2({text:null,id:null});
            setLoc3({text:null,id:null});
            setLoc2vis(true);
            setLoc3vis(false);
            setAfterLocVis(false);
            setSubmissionError(false);

            if (fvTgtVal1 === 'Inpatient'){
                setLoc2lbl('Hospital');   
            } else if (fvTgtVal1 === 'Outpatient'){
                setLoc2lbl('Clinic');   
            } else if ( (fvTgtVal1 === 'Remote') || (fvTgtVal1 ==='Home Health') || (fvTgtVal1 ==='Corporate') ){
                setLoc2lbl('State');   
            } else {
                setLoc2lbl('ERROR'); 
            }
        } else if (fvTgtName === 'loc2-select') {
            //let fvTgtVal2 = fvTgtVal as string;
            setLoc2(event.target.value);
            setLoc3vis(true);

            if (loc2lbl === 'Hospital'){
                setLoc3lbl('Unit');   
            } else if (loc2lbl === 'Clinic'){
                setLoc3lbl('Specialty');   
            } else if (loc2lbl === 'State'){
                setLoc3lbl('PSA');   
            } else {
                setLoc3lbl('ERROR'); 
            }
        } else if (fvTgtName === 'loc3-select') {
            //let fvTgtVal3 = fvTgtVal as string;
            setLoc3(event.target.value);
            setAfterLocVis(true);
        }
        
    }

    const followUpRadioButtons = [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
    ]  

    const roleRadioButtons = [
        { label: "Doctor", value: "Doctor" },
        { label: "Nurse", value: "Nurse" },
        { label: "Leadership", value: "Leadership" },
        { label: "Clinical", value: "Clinical" },
        { label: "Nonclinical", value: "Nonclinical" },
    ]  
    const reasonRadioButtons = [
        { label: "Difficult patient interaction", value: "Difficult patient interaction" },
        { label: "Difficult colleague interaction", value: "Difficult colleague interaction" },
        { label: "Personal", value: "Personal" },
        { label: "Other", value: "Other" },
    ]  
    const typeRadioButtons = [
        { label: "Initial", value: "Initial" },
        { label: "First Follow-Up", value: "1st Follow-Up" },
        { label: "Second Follow-Up", value: "2nd Follow-Up" },
        { label: "Third Follow-Up", value: "3rd Follow-Up" },
    ]  

    const loc1DropDown = [
        { text: "Inpatient", id: "Inpatient" },
        { text: "Outpatient", id: "Outpatient" },
        { text: "Corporate", id: "Corporate" },
        { text: "Remote", id: "Remote" },
        { text: "Home Health", id: "Home Health" },
    ]  

    const loc2render = () => {
        return (
            <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-6'>
                <div className='font-bold text-gray-900'>
                Encounter {loc2lbl}:
                </div>
                <div>
                    {loc2lbl === 'Hospital' &&
                     <DropDownList
                        style={{
                        width: "200px",
                        fontSize: "16px"
                        }}
                        name="loc2-select"
                        id="loc2-select"
                        onChange={handleKDDChange}
                        data={hospitals}
                        textField="text"
                        dataItemKey="id"
                        size={'large'}
                        value={loc2}
                        />                        
                    }
                    {loc2lbl === 'Clinic' &&
                        <DropDownList
                        style={{
                        width: "200px",
                        fontSize: "16px"
                        }}
                        name="loc2-select"
                        id="loc2-select"
                        onChange={handleKDDChange}
                        data={clinics}
                        textField="text"
                        dataItemKey="id"
                        size={'large'}
                        value={loc2}
                        />                        
                    }
                    {loc2lbl === 'State' &&
                        <DropDownList
                        style={{
                        width: "200px",
                        fontSize: "16px"
                        }}
                        name="loc2-select"
                        id="loc2-select"
                        onChange={handleKDDChange}
                        data={states}
                        textField="text"
                        dataItemKey="id"
                        size={'large'}
                        value={loc2}
                        />                           
                    }
                  
                </div>
            </div>
          
        )
    }

    const loc3render = () => {
        return (
            <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-6'>
                <div className='font-bold text-gray-900'>
                Encounter {loc3lbl}:
                </div>
                <div>
                    {loc3lbl === 'Unit' &&
                        <DropDownList
                        style={{
                        width: "200px",
                        fontSize: "16px"
                        }}
                        name="loc3-select"
                        id="loc3-select"
                        onChange={handleKDDChange}
                        data={units}
                        textField="text"
                        dataItemKey="id"
                        size={'large'}
                        value={loc3}
                        /> 
                    }
                    {loc3lbl === 'Specialty' &&
                        <DropDownList
                        style={{
                        width: "200px",
                        fontSize: "16px"
                        }}
                        name="loc3-select"
                        id="loc3-select"
                        onChange={handleKDDChange}
                        data={specialties}
                        textField="text"
                        dataItemKey="id"
                        size={'large'}
                        value={loc3}
                        />                        
                    }
                    {loc3lbl === 'PSA' &&
                        <DropDownList
                        style={{
                        width: "200px",
                        fontSize: "16px"
                        }}
                        name="loc3-select"
                        id="loc3-select"
                        onChange={handleKDDChange}
                        data={psas}
                        textField="text"
                        dataItemKey="id"
                        size={'large'}
                        value={loc3}
                        />    
                    }
                  
                </div>
            </div>
        )
    }

    const afterLocRender = () => {
        return (
            <div>
                <div className='flex flex-col px-6 md:px-8 pt-6'>
                    <div className='flex-grow'>
                        <hr/>
                    </div>
                </div>
                <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-4'>
                    <div className='font-bold text-gray-900'>
                    Encounter Type:
                    </div>
                    <div>
                        <KRadioGroup 
                            style={{
                                fontSize: "16px"
                                }}
                            name="encounter-type-rbg" 
                            data={typeRadioButtons} 
                            layout={isDesktop? 'horizontal' : 'vertical'}
                            onChange={handleKRGChange}
                            aria-labelledby="encounter-type-radio-buttons-group"
                        />    
                    </div>
                </div>
                <div className='flex flex-col px-6 md:px-8 pt-4'>
                    <div className='flex-grow'>
                        <hr/>
                    </div>
                </div>
                <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-6'>
                    <div className='font-bold text-gray-900'>
                    Encounter Reason:
                    </div>
                    <div>
                        <KRadioGroup 
                            style={{
                                fontSize: "16px"
                                }}
                            name="encounter-reason-rbg" 
                            data={reasonRadioButtons} 
                            layout='vertical'
                            onChange={handleKRGChange}
                            aria-labelledby="encounter-reason-radio-buttons-group"
                        />   
                    </div>
                </div>
                <div className='flex flex-col px-6 md:px-8 pt-4'>
                    <div className='flex-grow'>
                        <hr/>
                    </div>
                </div>
                <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-6'>
                    <div className='font-bold text-gray-900'>
                    Role of Encounter Individual:
                    </div>
                    <div>
                        <KRadioGroup 
                            style={{
                                fontSize: "16px"
                                }}                        
                            name="encounter-role-rbg" 
                            data={roleRadioButtons} 
                            layout={isDesktop? 'horizontal' : 'vertical'}
                            onChange={handleKRGChange}
                            aria-labelledby="encounter-role-radio-buttons-group"
                        />   
                    </div>
                </div>
                <div className='flex flex-col px-6 md:px-8 pt-4'>
                    <div className='flex-grow'>
                        <hr/>
                    </div>
                </div>
                <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-6'>
                    <div className='font-bold text-gray-900'>
                    Do They Want You to Follow-Up?
                    </div>
                    <div>
                        <KRadioGroup 
                            style={{
                                fontSize: "16px"
                                }}                        
                            name="encounter-followup-rbg" 
                            data={followUpRadioButtons} 
                            layout='horizontal'
                            onChange={handleKRGChange}
                            aria-labelledby="encounter-followup-radio-buttons-group"
                        />
                    </div>
                </div>
                <div className='flex flex-col px-6 md:px-8 pt-4'>
                    <div className='flex-grow'>
                        <hr/>
                    </div>
                </div>
                <div className='flex flex-row gap-3 items-center justify-center md:justify-start px-6 md:px-8 pt-6'>
                    <div>
                        <Button name="submit-button" variant="outlined" size="large" startIcon={<SendIcon />}  onClick={() => {handleClick('Submit'); }} {...(submissionError && {color:"error"})} >
                            Submit
                        </Button>
                    </div>
                    <div>
                        <Button name="clear-button" variant="contained" size="large" endIcon={<BorderClearOutlinedIcon />} onClick={() => {handleClick('Clear'); }}>
                            Clear
                        </Button>
                    </div>
                </div>
            </div>           
        )

    }

    const datePickerMaxDate = new Date();
    //let beginningMaxDate: Date = new Date(eDate.setUTCHours(0,0,0,0));

    //bgcolor: '#e7eefc',
    return (
        <React.Fragment>
        {isLoading ? (
            <NNLoader/>
        ) : (
        <div className="relative pt-8 py-8 bg-slate-100 overflow-hidden">
            <div className='flex flex-col px-8'>
                <div className='flex-grow text-center text-3xl md:text-left md:text-4xl leading-8 font-extrabold tracking-tight text-gray-900'>
                    Peer Support Check-In
                </div>
            </div>
            <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-8 md:pt-10'>
                <div className='font-bold text-gray-900'>
                    Encounter Date:
                </div>
                <div className='text-base'>
                    <DatePicker name='enc-date-picker' size='large' defaultValue={eDate} defaultShow={false} onChange={handleDateChange} max={datePickerMaxDate} />
                </div>
            </div>
            <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-6'>
                <div className='font-bold text-gray-900'>
                    Approximate Encounter Time:
                </div>
                <div>
                    <TimePicker name='enc-time-picker' format='HH:mm' size='large' defaultValue={eDate} defaultShow={false} onChange={handleTimeChange} />
                </div>
            </div>
            <div className='flex flex-row gap-3 items-center px-6 md:px-8 pt-6'>
                <div className='font-bold text-gray-900'>
                    Encounter Location:
                </div>
                <div>
                    <DropDownList
                        style={{
                        width: "200px",
                        fontSize: "16px"
                        }}
                        name="loc1-select"
                        id="loc1-select"
                        onChange={handleKDDChange}
                        data={loc1DropDown}
                        textField="text"
                        dataItemKey="id"
                        value={loc1}
                    />                   
                </div>
            </div>
            {loc2vis === true &&
                loc2render()
            }
            {loc3vis === true &&
                loc3render()
            }
            {afterlocVis === true &&
                afterLocRender()
            }
            <style>{`
                hr {
                    height:2px;
                    border-width:0;
                    background-color:#f0f0f0;
                }
                .k-animation-container li, .k-animation-container-shown li {
                    font-size: 16px;
               }
               .k-calendar, .k-input-inner {
                    font-size: 16px;
                }
            `}</style>
            </div>
            )}
        </React.Fragment>


    )
}

export default Encounters;