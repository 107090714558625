/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFollowup = /* GraphQL */ `
  mutation CreateFollowup(
    $input: CreateFollowupInput!
    $condition: ModelFollowupConditionInput
  ) {
    createFollowup(input: $input, condition: $condition) {
      id
      followupDate
      isComplete
      completionDate
      createdAt
      updatedAt
    }
  }
`;
export const updateFollowup = /* GraphQL */ `
  mutation UpdateFollowup(
    $input: UpdateFollowupInput!
    $condition: ModelFollowupConditionInput
  ) {
    updateFollowup(input: $input, condition: $condition) {
      id
      followupDate
      isComplete
      completionDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollowup = /* GraphQL */ `
  mutation DeleteFollowup(
    $input: DeleteFollowupInput!
    $condition: ModelFollowupConditionInput
  ) {
    deleteFollowup(input: $input, condition: $condition) {
      id
      followupDate
      isComplete
      completionDate
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userName
      givenName
      familyName
      pronouns
      email
      phone
      location1
      location2
      location3
      job
      userRole
      Encounters {
        items {
          id
          userName
          encounterDate
          encounterTime
          encounterLoc1
          encounterLoc2
          encounterLoc3
          encounterType
          encounterReason
          encounterRole
          encounterFollowUp
          userID
          Followup {
            id
            followupDate
            isComplete
            completionDate
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          encounterFollowupId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userName
      givenName
      familyName
      pronouns
      email
      phone
      location1
      location2
      location3
      job
      userRole
      Encounters {
        items {
          id
          userName
          encounterDate
          encounterTime
          encounterLoc1
          encounterLoc2
          encounterLoc3
          encounterType
          encounterReason
          encounterRole
          encounterFollowUp
          userID
          Followup {
            id
            followupDate
            isComplete
            completionDate
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          encounterFollowupId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userName
      givenName
      familyName
      pronouns
      email
      phone
      location1
      location2
      location3
      job
      userRole
      Encounters {
        items {
          id
          userName
          encounterDate
          encounterTime
          encounterLoc1
          encounterLoc2
          encounterLoc3
          encounterType
          encounterReason
          encounterRole
          encounterFollowUp
          userID
          Followup {
            id
            followupDate
            isComplete
            completionDate
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          encounterFollowupId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEncounter = /* GraphQL */ `
  mutation CreateEncounter(
    $input: CreateEncounterInput!
    $condition: ModelEncounterConditionInput
  ) {
    createEncounter(input: $input, condition: $condition) {
      id
      userName
      encounterDate
      encounterTime
      encounterLoc1
      encounterLoc2
      encounterLoc3
      encounterType
      encounterReason
      encounterRole
      encounterFollowUp
      userID
      Followup {
        id
        followupDate
        isComplete
        completionDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      encounterFollowupId
    }
  }
`;
export const updateEncounter = /* GraphQL */ `
  mutation UpdateEncounter(
    $input: UpdateEncounterInput!
    $condition: ModelEncounterConditionInput
  ) {
    updateEncounter(input: $input, condition: $condition) {
      id
      userName
      encounterDate
      encounterTime
      encounterLoc1
      encounterLoc2
      encounterLoc3
      encounterType
      encounterReason
      encounterRole
      encounterFollowUp
      userID
      Followup {
        id
        followupDate
        isComplete
        completionDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      encounterFollowupId
    }
  }
`;
export const deleteEncounter = /* GraphQL */ `
  mutation DeleteEncounter(
    $input: DeleteEncounterInput!
    $condition: ModelEncounterConditionInput
  ) {
    deleteEncounter(input: $input, condition: $condition) {
      id
      userName
      encounterDate
      encounterTime
      encounterLoc1
      encounterLoc2
      encounterLoc3
      encounterType
      encounterReason
      encounterRole
      encounterFollowUp
      userID
      Followup {
        id
        followupDate
        isComplete
        completionDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      encounterFollowupId
    }
  }
`;
