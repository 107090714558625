import { I18n } from 'aws-amplify';
import '@progress/kendo-theme-default/dist/all.css';
import * as React from 'react';
import { Logger, Hub, DataStore } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Amplify, API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Header } from "./authentication/Header";
import { Footer } from "./authentication/Footer";
import { SignInHeader } from "./authentication/SignInHeader";
import { SignInFooter } from "./authentication/SignInFooter";
import "./authentication/styles.css";

import DrawerRouterContainer from './components/DrawerRouterContainer';
import DrawerRouterContainerAdmin from './components/DrawerRouterContainerAdmin';

import { UserContext } from './UserContext';
import MainLanding from './pages/MainLanding';
import Encounters from './pages/Encounters';
import Dashboard from './pages/Dashboard';
import Information from './pages/Information';

import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

//Global Amplify logger setting
Amplify.Logger.LOG_LEVEL = 'DEBUG';
//Amplify.Logger.LOG_LEVEL = 'INFO';
//Amplify.Logger.LOG_LEVEL = 'WARN';

const logger = new Logger('Main', 'INFO');

export function Main({ signOut, user } : {signOut:any, user:any}) {

  Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signOut') {
      logger.debug("SIGNOUT: Clear Datastore on signout");
      await DataStore.clear();
    }
  });

    let isAdmin:boolean| undefined;
    let group:string | undefined;

    //logger.debug("Main | 1.0 - user cognito obj - " + JSON.stringify(user));

    function determineAdminGroup (user: any) {
      logger.debug("determineAdminGroup | 1.0");
      /*TODO: 
        assuming only one Cognito Group defined; if more then will need to insert logic to support
        If there are zero Groups assigned to a User, then "cognito:groups" will be undefined
        However if there is more than one Group, then you may need to treat it as an Array, aka ["cognito:groups"][0] 
      */
        group = user.signInUserSession.accessToken.payload["cognito:groups"];

      if (group === undefined){
        logger.debug("determineAdminGroup | 2.0 - user cognito group is undefined - " + group);
        isAdmin = false;
        group = "Ambassador"
      } else {
        logger.debug("determineAdminGroup | 3.0 - user cognito group has a value - " + group);
        isAdmin = true;
        group = "Admin"
      }
      //return 
    }

    /*TODO:  
        role is just a placeholder for the UserContext.Provider value={{...role, }}
        Need to research why placing ...role in the UserContext.Provider renders the attribute null
        but all other attributes work as intended
    */
    const role = user.attributes.given_name;
    const email = user.attributes.email;
    const firstName = user.attributes.given_name;
    const lastName = user.attributes.family_name;
    const initials = firstName.charAt(0) + lastName.charAt(0);
    logger.debug("Main | 3.1 - user attribute email - " + email);
    determineAdminGroup(user);

    function adminView (){
      logger.debug("adminView | 1.0");
      return (
        <DrawerRouterContainerAdmin group={String(group)} ampSignOut={signOut} >
        <Routes>
          <Route path="/" element={<MainLanding />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="encounters" element={<Encounters />} />
          <Route path="information" element={<Information />} />
        </Routes>
        </DrawerRouterContainerAdmin>
      )
    }

    function ambassadorView() {
      logger.debug("ambassadorView | 1.0");
      return (
        <DrawerRouterContainer group={String(group)} ampSignOut={signOut} >
        <Routes>
          <Route path="/" element={<MainLanding />} />
          <Route path="encounters" element={<Encounters />} />
          <Route path="information" element={<Information />} />
        </Routes>
        </DrawerRouterContainer>
      )
    }
    const localeId = 'en-US';

    return (
    
      <div className="Main" style={{backgroundColor:'#ffffff'}}>
        <LocalizationProvider language={localeId}>
            <IntlProvider locale={localeId}>
              <UserContext.Provider value={{...role, email, lastName, firstName, initials, group, signOut}}>
                <BrowserRouter>
                  {
                    isAdmin === true &&
                    adminView()
                  }
                  {
                    isAdmin === false &&
                    ambassadorView()
                  }
                </BrowserRouter>
              </UserContext.Provider>
            </IntlProvider>
        </LocalizationProvider>
      </div>

    );
}

export default withAuthenticator(Main, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});

I18n.putVocabulariesForLanguage('en', {
  'Create Account': 'Register', // Tab header
  'Create a new account': 'New User', // Header text
  'Confirm Password': 'Confirm your password', // Confirm Password label
  'Given Name' : 'First Name',
  'Family Name' : 'Last Name',
  Email: 'Enter your email',
  'Phone Number': 'Enter your phone number',
});