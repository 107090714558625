import { Logger, API  } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import jsonata from 'jsonata';
import { formatDate } from '@telerik/kendo-intl';
import Encounter from '../classes/Encounter';
import FollowUp from '../classes/FollowUp';
import {createEncounter, createFollowup, updateEncounter, updateFollowup} from '../graphql/mutations';
import { listEncounters, getFollowup } from '../graphql/queries';

const logger = new Logger('DBHelper', 'INFO');

export async function createEncounterAndFollowUp(anEncounter: Encounter){
    logger.debug(" createEncounterAndFollowUp | 1.0 | anEncounter.eDate - " + anEncounter.eDate);

    let newEncounter: any;
    let newFollowUp: any;
    let updatedEncounter: any;
    try {
        newEncounter = await createNewEncounter(anEncounter);
        
        const newEncounterFollowUpFlag = newEncounter.data.createEncounter.encounterFollowUp;
        logger.debug(" createEncounterAndFollowUp | 2.0 | newEncounterFollowUpFlag - " + newEncounterFollowUpFlag);
        if (newEncounterFollowUpFlag === true) {
            
            newFollowUp = await createFollowUp(newEncounter);
            updatedEncounter = await updateEncounterWithFollowUp(newEncounter, newFollowUp);
            logger.debug(" createEncounterAndFollowUp | 3.0 | updatedEncounter - " + JSON.stringify(updatedEncounter));
            return updatedEncounter;
        }
        
    } catch(event) {
        logger.debug(" createEncounterAndFollowUp | 4.0 | ERROR - " + JSON.stringify(event));
    }

    logger.debug(" createEncounterAndFollowUp | 5.0 | newEncounter - " + JSON.stringify(newEncounter));

    return newEncounter;

}

export async function createNewEncounter(anEncounter: Encounter) {
    logger.debug(" createNewEncounter | 1.0 | anEncounter.eDate - " + anEncounter.eDate);
    
    let newEncounterDetails = {
        id: uuidv4(),
        userName: anEncounter.userName,
        encounterDate: anEncounter.eDate,
        encounterTime: anEncounter.eTime,
        encounterLoc1: anEncounter.loc1,
        encounterLoc2: anEncounter.loc2,
        encounterLoc3: anEncounter.loc3,
        encounterType: anEncounter.eType,
        encounterReason: anEncounter.eReason,
        encounterRole: anEncounter.eRole,
        encounterFollowUp: anEncounter.eFollowUp,
        userID: anEncounter.userName,
    };

    logger.debug(" createNewEncounter | 2.0 | newEncounterDetails - " + newEncounterDetails.encounterDate);
    logger.debug(" createNewEncounter | 2.1 | newEncounterDetails - " + JSON.stringify(newEncounterDetails));

    let newEncounter = await API.graphql( { 
        query: createEncounter, 
        variables: { input: newEncounterDetails },
        authMode: "AMAZON_COGNITO_USER_POOLS"
    } )
   
    logger.debug(" createNewEncounter | 3.0 | newEncounter - " + JSON.stringify(newEncounter));

    return newEncounter;   
}

async function createFollowUp(aNewEncounter: any) {
    logger.debug(" createFollowUp | 1.0");
    const newEncounterID = aNewEncounter.data.createEncounter.id;
    logger.debug(" createFollowUp | 1.1 | newEncounterID - " + newEncounterID);
    
    const newEncounterDate = aNewEncounter.data.createEncounter.encounterDate;
    logger.debug(" createFollowUp | 1.2 | newEncounterDate - " + newEncounterDate);
    const newEncounterType = aNewEncounter.data.createEncounter.encounterType;
    logger.debug(" createFollowUp | 1.3 | newEncounterType - " + newEncounterType);
    /*
        encounterType = 'Initial' 3 days, all others 7 days
    */
    const adjDate: Date = new Date(newEncounterDate);
    if (newEncounterType === 'Initial') {
        logger.debug(" createFollowUp | 2.0 | adjDate.getDate() - " + adjDate.getDate());
        adjDate.setDate(adjDate.getDate() + 3);
        logger.debug(" createFollowUp | 2.3 | adjDate.toLocaleString() - " + adjDate.toLocaleString());
    } else {
        logger.debug(" createFollowUp | 3.0 | adjDate.getDate() - " + adjDate.getDate());
        adjDate.setDate(adjDate.getDate() + 7);
        logger.debug(" createFollowUp | 3.2 | adjDate.toLocaleString() - " + adjDate.toLocaleString());
    }

    logger.debug(" createFollowUp | 4.0");

    let newFollowDetails = {
        id: uuidv4(),
        followupDate: adjDate,
        isComplete: false,
    };

    let newFollowUp = await API.graphql( { 
        query: createFollowup, 
        variables: { input: newFollowDetails },
        authMode: "AMAZON_COGNITO_USER_POOLS"
    } )
   
    logger.debug(" createFollowUp | 4.0 | newFollowUp - " + JSON.stringify(newFollowUp));

    return newFollowUp;      

}

async function updateEncounterWithFollowUp(aEncounter: any, aFollowUp: any) {
    logger.debug(" updateEncounterWithFollowUp | 1.0");
    logger.debug(" updateEncounterWithFollowUp | 1.1 | aFollowUp - " + JSON.stringify(aFollowUp));
    logger.debug(" updateEncounterWithFollowUp | 1.2 | aEncounter - " + JSON.stringify(aEncounter));
    const aFollowUpID = aFollowUp.data.createFollowup.id;
    logger.debug(" updateEncounterWithFollowUp | 1.3 | aFollowupID - " + aFollowUpID);
    
    const aEncounterID = aEncounter.data.createEncounter.id;
    logger.debug(" updateEncounterWithFollowUp | 1.4 | aEncounterID - " + aEncounterID);

    let encounterUpdateDetails = {
        id: aEncounterID,
        encounterFollowupId: aFollowUpID,
    };

    let updatedEncounter = await API.graphql( { 
        query: updateEncounter, 
        variables: { input: encounterUpdateDetails },
        authMode: "AMAZON_COGNITO_USER_POOLS"
    } )
   
    logger.debug(" updateEncounterWithFollowUp | 3.0 | updatedEncounter - " + JSON.stringify(updatedEncounter));

    return updatedEncounter;      
}

export async function updateFollowUpComplete(pFollowUpID: string) {
    logger.debug(" updateFollowUpComplete | 1.0");
    logger.debug(" updateFollowUpComplete | 1.1 | pFollowUpID - " + pFollowUpID);
    
    var usrInput: {[k: string]: any} = {};

    //FollowUp completion date is set to the time they check completion
    //TODO: may need to give them an opportunity to put in a date for FollowUp completion
    usrInput.id = pFollowUpID;
    usrInput.completionDate = new Date();
    usrInput.isComplete = new Boolean(true);

    logger.info("updateUser | 2.0 - usrInput - " + JSON.stringify(usrInput));

    let updatedFollowUp = await API.graphql( { 
        query: updateFollowup, 
        variables: { input: usrInput },
        authMode: "AMAZON_COGNITO_USER_POOLS"
    } )
   
    logger.debug(" updateFollowUpComplete | 3.0 | updatedFollowUp - " + JSON.stringify(updatedFollowUp));

    return updatedFollowUp;      
}

export async function queryEncountersForUser(aUserID: string) {
    logger.debug(" queryEncountersForUser | 1.0 | aUserID - " + aUserID);
  
    //Example filter with more than one parameter
    /*
    let filter = {
        and: [
            { userID: {eq:aUserID} },
            { encounterFollowUp: {eq:true} },
            { encounterLoc2: {eq:'Clinics 2'} }
        ]
    };
    */
 
    let filter = {
        userID: {
            eq: aUserID
        }    
    };

    const fQueryResults = await API.graphql({ query: listEncounters, variables: { filter: filter}});
    
    const rtnJson = JSON.parse(JSON.stringify(fQueryResults));
    logger.debug("queryEncountersForUser | 2.0 | rtnJson - " + JSON.stringify(fQueryResults));

    /*
        Iterate through the graphql return json of Encounter items map them to new Encounter class instances 
        then put them in a new Array
    */

    const itemCount = rtnJson.data.listEncounters.items.length;
    logger.debug("queryEncountersForUser | 2.1 | itemCount - " + itemCount);
    let encArr = new Array();
    let encObj: Encounter;
    for (let i=0; i < itemCount; i++) {
        encObj = createEncounterObject(rtnJson.data.listEncounters.items[i]);
        encArr.push(encObj);     
    }
    const sortedEncArr = encArr.sort(sortEncounters);
    
    //const finalArray = await createEncounterObjectArray(rtnJson);
    //logger.debug("queryEncountersForUser | 3.0 | finalArray - " + JSON.stringify(finalArray));
    
    return sortedEncArr;    
}

const createEncounterObject = (jsonItem: any):Encounter => {
    logger.debug("createEncounterObject | 1.0");
    const newEncounterObj = new Encounter(
            jsonItem.userName,
            new Date(formatDate(jsonItem.encounterDate, "d")),
            new Date(formatDate(jsonItem.encounterTime, "T")),
            jsonItem.encounterLoc1,
            jsonItem.encounterLoc2,
            jsonItem.encounterLoc3,
            jsonItem.encounterType,
            jsonItem.encounterReason,
            jsonItem.encounterRole,
            jsonItem.encounterFollowUp
    )
    newEncounterObj.ID = jsonItem.id;
    newEncounterObj.eFollowUpStr = jsonItem.encounterFollowUp === true ? 'Yes' : 'No';
    logger.debug("createEncounterObject | 2.1 - newEncounterObj - " + newEncounterObj.ID);
    logger.debug("createEncounterObject | 2.2 - jsonItem.ID - " + jsonItem.id);

    let followUpObj: FollowUp;
    if (jsonItem.encounterFollowUp === true) {
        logger.debug("createEncounterObject | 3.0");
        followUpObj = new FollowUp(
            new Date(formatDate(jsonItem.Followup.followupDate, "d")),
            jsonItem.Followup.isComplete,
        );
        followUpObj.ID = jsonItem.Followup.id;
        followUpObj.completionDate = new Date(formatDate(jsonItem.Followup.completionDate, "d"));
        followUpObj.isCompleteStr = jsonItem.Followup.isComplete === true ? 'Yes' : 'No';
        newEncounterObj.followUp = followUpObj;
        logger.debug("createEncounterObject | 4.0 | followUpObj - " + JSON.stringify(followUpObj));
    }
    
    return newEncounterObj;     
}

export async function queryEncountersAdmin() {
    logger.debug(" queryEncountersAdmin | 1.0");
  
    //Example filter with more than one parameter
    /*
    let filter = {
        and: [
            { userID: {eq:aUserID} },
            { encounterFollowUp: {eq:true} },
            { encounterLoc2: {eq:'Clinics 2'} }
        ]
    };

    const fQueryResults = await API.graphql({ query: listEncounters, variables: { filter: filter}});
    */
 
    const fQueryResults = await API.graphql({ query: listEncounters});
    
    const rtnJson = JSON.parse(JSON.stringify(fQueryResults));
    logger.debug("queryEncountersAdmin | 2.0 | rtnJson - " + JSON.stringify(fQueryResults));

    /*
        Iterate through the graphql return json of Encounter items map them to new Encounter class instances 
        then put them in a new Array
    */

    const itemCount = rtnJson.data.listEncounters.items.length;
    logger.debug("queryEncountersAdmin | 2.1 | itemCount - " + itemCount);
    let encArr = new Array();
    let encObj: Encounter;
    for (let i=0; i < itemCount; i++) {
        encObj = createEncounterObject(rtnJson.data.listEncounters.items[i]);
        encArr.push(encObj);     
    }
    const sortedEncArr = encArr.sort(sortEncounters);
    
    //const finalArray = await createEncounterObjectArray(rtnJson);
    //logger.debug("queryEncountersForUser | 3.0 | finalArray - " + JSON.stringify(finalArray));
    
    return sortedEncArr;    
}

async function createEncounterObjectArray(rtnJSON: any) {
    logger.debug("createEncounterObjectArray | 1.0");
    //Finding Encounters with FollowUps and putting them in an Object array
    const jsonataExpression = jsonata("data.listEncounters.items[encounterFollowupId!=null]");
    logger.debug("createEncounterObjectArray | 1.1 | jsonataExpression - " + jsonataExpression);
    const jsonataFwUpResult = jsonataExpression.evaluate(rtnJSON);
    let encFlwUpArr;
    if (jsonataFwUpResult !== undefined) {
        logger.debug("createEncounterObjectArray | 2.0 | jsonataFwUpResult - " + JSON.stringify(jsonataFwUpResult));
        const jsonataTest1 = jsonataFwUpResult.id;
        logger.debug("createEncounterObjectArray | 2.1 | jsonataNoFwUpResult.id - " + jsonataFwUpResult.id);
        encFlwUpArr = new Array();
        // > 1 records
        if (jsonataTest1 === undefined) {
            const jsonataItemCount = jsonataFwUpResult.length;
            logger.debug("createEncounterObjectArray | 2.4 | jsonataItemCount - " + jsonataItemCount);
            
            for (let i=0; i < jsonataItemCount; i++ ){
                const followUpID = jsonataFwUpResult[i].encounterFollowupId;
                logger.debug("createEncounterObjectArray | 3.0 | followUpID - " + followUpID);

                //NOTE: Query Get by ID has a different set of parameters than Query Lists
                const flwUpuQueryResults = await API.graphql({ 
                    query: getFollowup, 
                    variables: {id: followUpID}
                });
                logger.debug("createEncounterObjectArray | 3.4 | flwUpuQueryResults - " + JSON.stringify(flwUpuQueryResults));
                const rtnFlwUpJson = JSON.parse(JSON.stringify(flwUpuQueryResults));
                logger.debug("createEncounterObjectArray | 4.0 | rtnFlwUpJson - " + JSON.stringify(flwUpuQueryResults));
                const followUpObj = new FollowUp(
                    rtnFlwUpJson.data.getFollowup.followupDate,
                    rtnFlwUpJson.data.getFollowup.isComplete,
                );
                followUpObj.ID = rtnFlwUpJson.data.getFollowup.id;
                logger.debug("createEncounterObjectArray | 4.1 | followUpObj - " + JSON.stringify(followUpObj));

                const newEncounterObj = new Encounter(
                    jsonataFwUpResult[i].userName,
                    jsonataFwUpResult[i].encounterDate,
                    jsonataFwUpResult[i].encounterTime,
                    jsonataFwUpResult[i].encounterLoc1,
                    jsonataFwUpResult[i].encounterLoc2,
                    jsonataFwUpResult[i].encounterLoc3,
                    jsonataFwUpResult[i].encounterType,
                    jsonataFwUpResult[i].encounterReason,
                    jsonataFwUpResult[i].encounterRole,
                    jsonataFwUpResult[i].encounterFollowUp
                )
                newEncounterObj.ID = jsonataFwUpResult[i].id;  
                logger.debug("createEncounterObjectArray | 4.5 | newEncounterObj - " + JSON.stringify(newEncounterObj));
                newEncounterObj.followUp = followUpObj;
                logger.debug("createEncounterObjectArray | 4.6 | newEncounterObj - " + JSON.stringify(newEncounterObj));
                encFlwUpArr.push(newEncounterObj);
            }
        } else {
            //jsonata = 1 record only
            const followUpID = jsonataFwUpResult.encounterFollowupId;
            logger.debug("createEncounterObjectArray | 5.0 | followUpID - " + followUpID);

            const newEncounterObj = new Encounter(
                jsonataFwUpResult.userName,
                jsonataFwUpResult.encounterDate,
                jsonataFwUpResult.encounterTime,
                jsonataFwUpResult.encounterLoc1,
                jsonataFwUpResult.encounterLoc2,
                jsonataFwUpResult.encounterLoc3,
                jsonataFwUpResult.encounterType,
                jsonataFwUpResult.encounterReason,
                jsonataFwUpResult.encounterRole,
                jsonataFwUpResult.encounterFollowUp
            )
            newEncounterObj.ID = jsonataFwUpResult.id;        
            logger.debug("createEncounterObjectArray | 6.0 | newEncounterObj - " + JSON.stringify(newEncounterObj));
            encFlwUpArr.push(newEncounterObj);
        }
    }


    //Finding Encounters with NO FollowUps and putting them in a separate Object array
    const jsonataExpression2 = jsonata("data.listEncounters.items[encounterFollowupId=null]");
    logger.debug("createEncounterObjectArray | 7.0 | jsonataExpression2 - " + JSON.stringify(jsonataExpression2));
    const jsonataNoFwUpResult = jsonataExpression2.evaluate(rtnJSON);
    let encNoFlwUpArr;
    if (jsonataNoFwUpResult !== undefined) {
        logger.debug("createEncounterObjectArray | 7.1 | jsonataNoFwUpResult - " + JSON.stringify(jsonataNoFwUpResult));
        const jsonataTest2 = jsonataNoFwUpResult.id;
        logger.debug("createEncounterObjectArray | 7.2 | jsonataNoFwUpResult.id - " + jsonataNoFwUpResult.id);
        //jsonata: 0 records: undefined, 1 record: not null, > 1 records: undefined
        //because we check for 0 records as the jsonata.evaluate, we only need to check for 1 record or >1 records
        encNoFlwUpArr = new Array();
        //jsonata has > 1 record
        if (jsonataTest2 === undefined) {
            const jsonataItemCount2 = jsonataNoFwUpResult.length;
            logger.debug("createEncounterObjectArray | 8.0 | jsonataItemCount2 - " + jsonataItemCount2);
            for (let i=0; i < jsonataItemCount2; i++ ){
                const followUpID = jsonataNoFwUpResult[i].encounterFollowupId;
                logger.debug("createEncounterObjectArray | 9.0 | followUpID - " + followUpID);

                const newEncounterObj = new Encounter(
                    jsonataNoFwUpResult[i].userName,
                    jsonataNoFwUpResult[i].encounterDate,
                    jsonataNoFwUpResult[i].encounterTime,
                    jsonataNoFwUpResult[i].encounterLoc1,
                    jsonataNoFwUpResult[i].encounterLoc2,
                    jsonataNoFwUpResult[i].encounterLoc3,
                    jsonataNoFwUpResult[i].encounterType,
                    jsonataNoFwUpResult[i].encounterReason,
                    jsonataNoFwUpResult[i].encounterRole,
                    jsonataNoFwUpResult[i].encounterFollowUp
                )
                newEncounterObj.ID = jsonataNoFwUpResult[i].id;        
                logger.debug("createEncounterObjectArray | 9.5 | newEncounterObj - " + JSON.stringify(newEncounterObj));
                encNoFlwUpArr.push(newEncounterObj);
            }
        } else {
            //jsonata = 1 record only
            const followUpID = jsonataNoFwUpResult.encounterFollowupId;
            logger.debug("createEncounterObjectArray | 10.0 | followUpID - " + followUpID);

            const newEncounterObj = new Encounter(
                jsonataNoFwUpResult[0].userName,
                jsonataNoFwUpResult[0].encounterDate,
                jsonataNoFwUpResult[0].encounterTime,
                jsonataNoFwUpResult[0].encounterLoc1,
                jsonataNoFwUpResult[0].encounterLoc2,
                jsonataNoFwUpResult[0].encounterLoc3,
                jsonataNoFwUpResult[0].encounterType,
                jsonataNoFwUpResult[0].encounterReason,
                jsonataNoFwUpResult[0].encounterRole,
                jsonataNoFwUpResult[0].encounterFollowUp
            )
            newEncounterObj.ID = jsonataNoFwUpResult[0].id;        
            logger.debug("createEncounterObjectArray | 11.0 | newEncounterObj - " + JSON.stringify(newEncounterObj));
            encNoFlwUpArr.push(newEncounterObj);
        }
    }

    logger.debug("createEncounterObjectArray | 12.0 | encFlwUpArr - " + JSON.stringify(encFlwUpArr));
    logger.debug("createEncounterObjectArray | 12.1 | encNoFlwUpArr - " + JSON.stringify(encNoFlwUpArr));

    const combinedArray = [{key: "encounterWithFollowUp", value: encFlwUpArr}, {key: "encounterWithoutFollowUp", value: encNoFlwUpArr}];

    return combinedArray;     
}

// A function to sort Encounters most recent date to the top (front) of the Array
const sortEncounters = (a: Encounter, b: Encounter) =>{
    var dateA = new Date(a.eDate).getTime();
    var dateB = new Date(b.eDate).getTime();
    return dateA < dateB ? 1 : -1;  
}