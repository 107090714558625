/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFollowup = /* GraphQL */ `
  query GetFollowup($id: ID!) {
    getFollowup(id: $id) {
      id
      followupDate
      isComplete
      completionDate
      createdAt
      updatedAt
    }
  }
`;
export const listFollowups = /* GraphQL */ `
  query ListFollowups(
    $filter: ModelFollowupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        followupDate
        isComplete
        completionDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userName
      givenName
      familyName
      pronouns
      email
      phone
      location1
      location2
      location3
      job
      userRole
      Encounters {
        items {
          id
          userName
          encounterDate
          encounterTime
          encounterLoc1
          encounterLoc2
          encounterLoc3
          encounterType
          encounterReason
          encounterRole
          encounterFollowUp
          userID
          Followup {
            id
            followupDate
            isComplete
            completionDate
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          encounterFollowupId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        givenName
        familyName
        pronouns
        email
        phone
        location1
        location2
        location3
        job
        userRole
        Encounters {
          items {
            id
            userName
            encounterDate
            encounterTime
            encounterLoc1
            encounterLoc2
            encounterLoc3
            encounterType
            encounterReason
            encounterRole
            encounterFollowUp
            userID
            createdAt
            updatedAt
            encounterFollowupId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEncounter = /* GraphQL */ `
  query GetEncounter($id: ID!) {
    getEncounter(id: $id) {
      id
      userName
      encounterDate
      encounterTime
      encounterLoc1
      encounterLoc2
      encounterLoc3
      encounterType
      encounterReason
      encounterRole
      encounterFollowUp
      userID
      Followup {
        id
        followupDate
        isComplete
        completionDate
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      encounterFollowupId
    }
  }
`;
export const listEncounters = /* GraphQL */ `
  query ListEncounters(
    $filter: ModelEncounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncounters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        encounterDate
        encounterTime
        encounterLoc1
        encounterLoc2
        encounterLoc3
        encounterType
        encounterReason
        encounterRole
        encounterFollowUp
        userID
        Followup {
          id
          followupDate
          isComplete
          completionDate
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        encounterFollowupId
      }
      nextToken
    }
  }
`;
