/*
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/

import commhandspic from '../assets/images/community_hands_main2.jpg';

export default function InfoSection1() {
    return (
      <div className="relative py-8 bg-slate-100 overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                Peer Support Program
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Together as One
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            The Together as One Program is a peer support initiative that enables team members and physicians from all departments to recognize and intervene when a peer is impacted by stress within the workplace. 
            </p>
            <p className="mt-8 text-xl text-gray-500 leading-8">
            Implementation of this program is a component of a systemic culture shift towards a safer and more supportive environment that will reduce burnout and enhance resiliency by establishing a network of support capable of responding to normal workplace stressors.
            </p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <figure>
              <img
                className="w-full rounded-lg"
                src={commhandspic}
                alt=""
                width={1310}
                height={873}
              />
              <figcaption></figcaption>
            </figure>
            <h2 className="mt-2">FAQ</h2>
            <h3>What is a Peer Support Ambassador?</h3>
            <p>
            A Peer Support Ambassador is any Advocate Aurora Health team member or physician who has completed the Together as One Peer Support training and wears an Ambassador badge when available to provide immediate, brief intervention in response to daily stressors or high stress situations within the workplace.
            </p>
            <h3>Who can become a Peer Support Ambassador?</h3>
            <p>
            All Advocate Aurora physicians and team members are eligible for training across all departments (clinical and non-clinical).
            </p>
            <h3>What if there is not a Peer Support Ambassador present when a support is needed?</h3>
            <p>
            Team members may use the Together as One Program Access Line (708-520-WELL) to connect with a Peer Support Ambassador or any additional specialized Advocate Aurora resources.
            </p>
            <h3>What if a physician or team member needs more than the peer support that a Peer Support Ambassador can offer?</h3>
            <p>
            Peer Support Ambassadors are trained to connect peers with resources, or the team member may contact specialized resources through the Together as One Program Access Line (708-520-WELL). Resources available include: Mission and Spiritual Care, Employee Assistance Program and Trauma Recovery Center.
            </p>
          </div>
        </div>
      </div>
    )
  }
  