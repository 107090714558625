import FollowUp from "./FollowUp";

export class Encounter {
    constructor(aUserName: string, aEDate: Date, aETime: Date, aLoc1: string, aLoc2: string, aLoc3: string, aType: string, aReason: string, aRole: string, aFollowUp: boolean) {
        this.userName = aUserName;
        this.eDate = aEDate;
        this.eTime = aETime;
        this.loc1 = aLoc1;
        this.loc2 = aLoc2;
        this.loc3 = aLoc3;
        this.eType = aType;
        this.eReason = aReason;
        this.eRole = aRole;
        this.eFollowUp = aFollowUp;
        this.eFollowUpStr = "No"
        this.ID = '';
        this.followUp = null;
        this.expanded = false;
    }    
    
    ID: string;
    userName : string; //user email
    eDate: Date;
    eTime: Date;
    loc1: string;
    loc2: string;
    loc3: string;
    eType: string;
    eReason: string;
    eRole: string;
    eFollowUp: boolean;
    eFollowUpStr: string;
    followUp: FollowUp|null;
    expanded: boolean;
}

export default Encounter;
