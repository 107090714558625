import * as React from 'react';
import { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from '../UserContext';
import { Logger } from 'aws-amplify';
import { Grid as KGrid, GridColumn, GridRowClickEvent, GridExpandChangeEvent, GridDataStateChangeEvent, GridGroupChangeEvent} from "@progress/kendo-react-grid";
import { State, DataResult, process, GroupDescriptor, groupBy, GroupResult } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { ExcelExport, ExcelExportColumn, ExcelExportColumnGroup } from "@progress/kendo-react-excel-export";
import {
  setGroupIds,
} from "@progress/kendo-react-data-tools";

import Encounter from '../classes/Encounter';
import { EncounterAdminTableRowDetailComponent } from './EncounterAdminTableRowDetailComponent';

const logger = new Logger('EncountersAdminTable', 'INFO');

const gridColumnsLarge = [
  {
    field: 'userName',
    title: 'User Name',
    show: true,
  },  {
    field: 'eDate',
    title: 'Date',
    format: '{0:E, MMM d, y}',
    show: true,
  },
  {
      field: 'eTime',
      title: 'Time',
      format: '{0:HH:mm}',  
      show: true,  
  },
  {
      field: 'loc2',
      title: 'Location',
      show: true,
  },
  {
      field: 'eType',
      title: 'Type',
      show: true,
  },
  {
      field: 'eFollowUpStr',
      title: 'Follow-Up',
      show: true,
  },
  {
      field: 'followUp.isCompleteStr',
      title: 'Follow-Up Complete',
      show: true,
  },
]

const gridColumnsSmall = [
{
  field: 'eDate',
  title: 'Date',
  format: '{0:E, MMM d, y}',
  show: true,
},
{
  field: 'eTime',
  title: 'Time',
  format: '{0:HH:mm}',  
  show: true,  
},
{
  field: 'loc2',
  title: 'Location',
  show: true,
},
]

const initialGroup: GroupDescriptor[] = [
    { field: "userName" },
  ];

interface IEncountersAdminTableProps {
    encDataProp: Encounter[];
}

const EncountersAdminTable = ({ encDataProp } : IEncountersAdminTableProps) => {

    const componentIsMounted = useRef(true);
    const user = useContext(UserContext);
    const [encData, setEncData] = useState<Encounter[]>(encDataProp.map((dataItem: Encounter) =>
            Object.assign({ selected: false}, dataItem)
    )); //All Encounters
    //window size change state - min width est by tailwind
    const [isDesktop, setDesktop] = useState(window.innerWidth > 639);

    const updateMedia = () => {
      setDesktop(window.innerWidth > 639);
    };

    useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
    });  
    
    const [dataState, setDataState] = React.useState<State>({
        skip: 0,
        take: 20,
        sort: [{ field: "eDate", dir: "asc" }],
        group: [{ field: "userName" }],
    });

      const [dataResult, setDataResult] = React.useState<DataResult>(
        process(encData, dataState)
      );

      const dataStateChange = (event: GridDataStateChangeEvent) => {
        setDataResult(process(encData, event.dataState));
        setDataState(event.dataState);
      };

      const expandChange = (event: GridExpandChangeEvent) => {
        const isExpanded =
          event.dataItem.expanded === undefined
            ? event.dataItem.aggregates
            : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
    
        setDataResult({ ...dataResult });
      };    

    const handleRowClick = (event: GridRowClickEvent) => {
        logger.debug("handleRowClick | 1.0");
        logger.debug("handleRowClick | 1.1 - event.dataItem - " + JSON.stringify(event.dataItem));
    };

    useEffect(() => {
        logger.debug("useEffect | 1.0");
        if (encDataProp) {
            logger.debug("useEffect | 1.1");
            setEncData(encDataProp);
            setDataResult(process(encDataProp, dataState));
        }
        }, [encDataProp])        

    useEffect(() => {
        // each useEffect can return a cleanup function
        return () => {
            componentIsMounted.current = false;
        };
    }, []); // no extra deps => the cleanup function run this on component unmount

    useEffect(() => {
        async function queryEncounters() {
            try {
                logger.debug("queryEncounters | 1.0 - aUserID - " + user.email);

                if (componentIsMounted.current) {
                    logger.debug("queryEncounters | 2.0 - componentIsMounted.current - " + componentIsMounted.current);  
                }
            } catch (err) {
                console.error(err);
                logger.debug("queryEncounters | 5.0 - err - " + err);
        }
    }    
    queryEncounters();
    }, []);          

    const exportExcel = () => {
        _export.save();
      };

      let _export: any;

    return (

        <div>
          <ExcelExport
            data={encData}
            ref={(exporter) => {
              _export = exporter;
            }}
          >    
            <ExcelExportColumn field="userName" title="User Name" />
            <ExcelExportColumn 
                field="eDate" 
                title="Encounter Date" 
                cellOptions={{ format: "m/d/yyyy" }}
                width={150}
            />
            <ExcelExportColumn 
                field="eTime" 
                title="Encounter Time"
                cellOptions={{ format: "h:mm:ss" }}
                width={150} 
            />
            <ExcelExportColumn field="loc1" title="Location 1" />
            <ExcelExportColumn field="loc2" title="Location 2" />
            <ExcelExportColumn field="loc3" title="Location 3" />
            <ExcelExportColumn field="eType" title="Encounter Type" />
            <ExcelExportColumn field="eReason" title="Encounter Reason" />
            <ExcelExportColumn field="eFollowUp" title="Follow-Up" />
            <ExcelExportColumn field="followUp.isComplete" title="Follow-Up Complete" />
        </ExcelExport>       
        <KGrid 
            style={{ height: "800px", fontSize: "12", marginTop:"11px" }} 
            sortable={true} 
            filterable={true}
            groupable={true}
            reorderable={true}
            pageable={{ buttonCount: 4, pageSizes: true }}
            data={dataResult}
            {...dataState}
            onDataStateChange={dataStateChange}
            detail={EncounterAdminTableRowDetailComponent}
            expandField="expanded"
            onExpandChange={expandChange}
            resizable={true}
        >
            {isDesktop &&
            gridColumnsLarge.map((column, index) => {
            return column.show && <GridColumn key={index} field={column.field} title={column.title} format={column.format}/>
            })
            }
            {!isDesktop &&
            gridColumnsSmall.map((column, index) => {
            return column.show && <GridColumn key={index} field={column.field} title={column.title} format={column.format}/>
            })
            }
        </KGrid>  

        <button
                  title="Export to Excel"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={exportExcel}
                >
                  Export to Excel
        </button>
    </div> 

    )

}

export default EncountersAdminTable;