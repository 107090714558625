import * as React from 'react';
import InfoSection1 from './InfoSection1';

const Information = () => {

    return (
        <React.Fragment>
            <InfoSection1/>
        </React.Fragment>
    )
}

export default Information;