import { Logger } from 'aws-amplify';
import {
  GridDetailRowProps,
} from "@progress/kendo-react-grid";
import { formatDate } from '@telerik/kendo-intl';

const logger = new Logger('EncounterAdminTableRowDetailComponent', 'INFO');
/*
    Loc1 : Loc2 : Loc3
    Inpatient - Hospital, Unit
    Outpatient - Clinic, Specialty
    Corporate/Remote/Home Health - State, PSA
*/
export const EncounterAdminTableRowDetailComponent = (props: GridDetailRowProps) => {
    const dataItem = props.dataItem;
    const loc1 = dataItem.loc1;
    const hasFollowUp = dataItem.eFollowUp;
    logger.debug("queryEncounters | 1.0 - loc1 - " + loc1);
    logger.debug("queryEncounters | 1.1 - hasFollowUp - " + hasFollowUp);    
    let fuIsComplete = false;
    if (hasFollowUp === true) {
        const isComplete = dataItem.followUp.isComplete;    
        logger.debug("queryEncounters | 1.2 - isComplete - " + isComplete);  
        if (isComplete === true) {
            fuIsComplete = true;
        }
    }

    



    switch (loc1) {
        case "Inpatient":
            return (
                <section>
                    <p>
                        <strong>Encounter Locale:</strong> {dataItem.loc1}
                    </p>                        
                    <p>
                        <strong>Encounter Unit:</strong> {dataItem.loc3}
                    </p>                                         
                    <p>
                        <strong>Encounter Role:</strong> {dataItem.eRole}
                    </p>
                    <p>
                        <strong>Encounter Reason:</strong> {dataItem.eReason}
                    </p>
                    <p>
                        <strong>Encounter Type:</strong> {dataItem.eType}
                    </p>  
                    {  hasFollowUp === true &&  
                    <p>
                        <strong>Follow-Up Date:</strong> { dataItem.followUp.followUpDate.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' }) }
                    </p> 
                    }
                    {  hasFollowUp === true && 
                    <p>
                        <strong>Follow-Up Complete:</strong> { dataItem.followUp.isCompleteStr }
                    </p>
                    }
                    {  fuIsComplete === true &&                     
                    <p>
                        <strong>Follow-Up Completion Date:</strong> { dataItem.followUp.completionDate.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' }) }
                    </p>
                    }                                                            
                </section>
            )

        case "Outpatient":
            return (
                <section>
                    <p>
                        <strong>Encounter Locale:</strong> {dataItem.loc1}
                    </p>                        
                    <p>
                        <strong>Encounter Specialty:</strong> {dataItem.loc3}
                    </p>                                         
                    <p>
                        <strong>Encounter Role:</strong> {dataItem.eRole}
                    </p>                        
                    <p>
                        <strong>Encounter Reason:</strong> {dataItem.eReason}
                    </p>
                    <p>
                        <strong>Encounter Type:</strong> {dataItem.eType}
                    </p>
                    {  hasFollowUp === true &&  
                    <p>
                        <strong>Follow-Up Date:</strong> { dataItem.followUp.followUpDate.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' }) }
                    </p> 
                    }
                    {  hasFollowUp === true && 
                    <p>
                        <strong>Follow-Up Complete:</strong> { dataItem.followUp.isCompleteStr }
                    </p>
                    }
                    {  fuIsComplete === true &&                     
                    <p>
                        <strong>Follow-Up Completion Date:</strong> { dataItem.followUp.completionDate.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' }) }
                    </p>
                    }                                                              
                </section>
            )

        case "Corporate":
        case "Remote":
        case "Home Health":
            return (
                <section>
                    <p>
                        <strong>Encounter Locale:</strong> {dataItem.loc1}
                    </p>                        
                    <p>
                        <strong>Encounter PSA:</strong> {dataItem.loc3}
                    </p>                                         
                    <p>
                        <strong>Encounter Role:</strong> {dataItem.eRole}
                    </p>                        
                    <p>
                        <strong>Encounter Reason:</strong> {dataItem.eReason}
                    </p>
                    <p>
                        <strong>Encounter Type:</strong> {dataItem.eType}
                    </p>
                    {  hasFollowUp === true &&  
                    <p>
                        <strong>Follow-Up Date:</strong> { dataItem.followUp.followUpDate.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' }) }
                    </p> 
                    }
                    {  hasFollowUp === true && 
                    <p>
                        <strong>Follow-Up Complete:</strong> { dataItem.followUp.isCompleteStr }
                    </p>
                    }
                    {  fuIsComplete === true &&                     
                    <p>
                        <strong>Follow-Up Completion Date:</strong> { dataItem.followUp.completionDate.toLocaleDateString("en-US", { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' }) }
                    </p>
                    }                                                              
                </section>
            )                

        default:
            return (
                <section>
                    <p>
                    <strong>Oops, something went wrong</strong>
                    </p>
                </section>
            )
    }
};