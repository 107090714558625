export const clinics = [
    {
        text: 'Clinics 1',
        id: 'Clinics 1'
    },
    {
        text: 'Clinics 2',
        id: 'Clinics 2'
    },
    {
        text: 'Clinics 3',
        id: 'Clinics 3'
    }
];