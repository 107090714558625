export const hospitals = [
    {
        text: 'Christ Hospital',
        id: 'Christ Hospital'
    },
    {
        text: 'Hospital 2',
        id: 'Hospital 2'
    },
    {
        text: 'Hospital 3',
        id: 'Hospital 3'
    }
];
