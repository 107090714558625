

export const stringToBoolean = (str: String) => {
    switch(str.toLowerCase()){
        case "false": 
        case "no": 
        case "0":
        case "": 
          return false;

    default:
        return true;
    }

}

export {}