import { Image, useTheme } from "@aws-amplify/ui-react";
import logo from '../assets/images/together-logo-black-xsmall-1.png'

export function Header() {
  const { tokens } = useTheme();

  return (
    <Image
      alt="Neural Connect"
      src={logo}
      padding={tokens.space.xxxs}
      flex='auto'
    />
  );
}