
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useContext } from 'react';
import { UserContext } from './../UserContext';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Avatar } from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
  } from "@progress/kendo-react-layout";
  import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";

import { locales } from './../resources/locales';

import { AppContext } from './../AppContext'

export const Header = (props: { onButtonClick: any }) => {
    const { localeId, onLanguageChange } = React.useContext(AppContext);
    const localizationService = useLocalization();

    const user = useContext(UserContext);

    const currentLanguage = locales.find(item => item.localeId === localeId);

    return (
        <React.Fragment>
            <AppBar className="appbar" themeColor={"dark"}>

            <AppBarSection className="appbarsec-menu-button">
                <div className="menu-button">
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={props.onButtonClick} width="32" height="32" fill="white" className="bi bi-list" viewBox="0 0 16 12">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                 </div>            
            </AppBarSection>            

            <AppBarSpacer
                style={{
                    width: 1,
                }}
            />

            <AppBarSection >
            <p className="mt-2 text-xl text-white font-bold leading-8">
                Together as One
            </p>          
            </AppBarSection>

            <AppBarSpacer />

            <AppBarSection>
                <div className="language-section hidden sm:block">
                    <h3 style={{ color: 'white' }}>{localizationService.toLanguageString('custom.tao', 'Language')}</h3>
                </div>          
            </AppBarSection>

            <AppBarSection>
                <div className="settings hidden sm:block">
                    <DropDownList
                        textField={'locale'}
                        dataItemKey={'localeId'}
                        data={locales}
                        value={currentLanguage}
                        onChange={onLanguageChange}
                        defaultItem={locales[0]}
                    />
                </div>
            </AppBarSection>

            <AppBarSection className="user-actions">
                <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
                    <BadgeContainer>
                        <span className="k-icon k-i-bell" />
                        <Badge
                            shape="dot"
                            themeColor="info"
                            size="small"
                            position="inside"
                        />
                    </BadgeContainer>
                </button>
                <span className="k-appbar-separator" />
            </AppBarSection>

            <AppBarSection>
                <Avatar type={'text'} rounded={'full'} size={'medium'}>
                    {
                        <p>{user.initials}</p>
                    }
                </Avatar>                
            </AppBarSection>

            <AppBarSpacer
                style={{
                    width: 4,
                }}
            />
            </AppBar>

            <style>{`
                body {
                    background: #ffffff;
                }
                .appbar {
                    background: #222b81;
                .title {
                    font-size: 18;
                    margin: 0;
                }
                .k-button k-button-md k-rounded-md k-button-solid k-button-solid-base {
                    padding: 0;
                }
            `}</style>

        </React.Fragment>
    );
}

Header.displayName = 'Header';
Header.propTypes = {
    page: PropTypes.string,
    onButtonClick: PropTypes.func
};