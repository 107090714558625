import React from 'react';

import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import '@progress/kendo-theme-default/dist/all.css';

import { withRouter, WithRouterProps } from './withRouter';

import { Logger } from 'aws-amplify';

import {Header} from './Header';

const logger = new Logger('DrawerRouterContainerAdmin', 'INFO');

const items = [
    { name: 'Main', icon: 'k-i-grid', selected: true , route: '/' },
    { name: 'Dashboard', icon: 'k-i-user', route: '/dashboard' },
    { name: 'Encounters', icon: 'k-i-plus-outline', route: '/encounters' },
    { name: 'Information', icon: 'k-i-information', route: '/information' },
    { separator: true },
    { name: 'Log Out', icon: 'k-i-logout', route: '/logout' },
    { separator: true },
];

export interface IDrawerRouterContainerProps extends WithRouterProps {
    group:String;
    ampSignOut:any;
    children: React.ReactChild;
}

export interface IDrawerRouterContainerState {
    expanded : boolean;
    selectedId: number;
    isSmallerScreen: boolean;
    isAdmin: boolean;
    currentId: number;
}

class DrawerRouterContainerAdmin extends React.Component<IDrawerRouterContainerProps, IDrawerRouterContainerState> {

    constructor(props: IDrawerRouterContainerProps) {
        super(props)

        this.state = {
            expanded: false,
            selectedId: items.findIndex(x => x.selected === true),
            isSmallerScreen: window.innerWidth < 768,
            isAdmin: false,
            currentId: items.findIndex(x => x.selected === true)
        }
    }    

    determineAdminGroup(){
    if (this.props.group === 'Ambassador'){
        logger.debug("determineAdminGroup | 1.0 - this.props.group - " + this.props.group);
        this.setState({ isAdmin: false});
      } else {
        logger.debug("determineAdminGroup | 2.0 - this.props.group - " + this.props.group);
        this.setState({ isAdmin: true})
      }
    }

    resizeWindow = () => {
        this.setState({ isSmallerScreen: window.innerWidth < 768 })
    }

    handleClick = () => {
        logger.debug("handleClick | 1.0")
        this.setState({expanded: !this.state.expanded})
    }

    handleSelect = (e: { itemIndex: any; itemTarget: { props: { route: any; }; }; }) => {
        logger.debug("handleSelect | 1.0")
        logger.debug("handleSelect | 1.5 - " + e.itemTarget.props.route);
        let mvRoute : string = e.itemTarget.props.route
        logger.debug("handleSelect | 1.55 - e.itemIndex - " + e.itemIndex);
        logger.debug("handleSelect | 1.75 - " + mvRoute.localeCompare("/logout"));

        if (this.state.isSmallerScreen === true ) { 
            this.setState({selectedId: e.itemIndex, expanded: false});
        } else {
            //compare last selection with the current selection
            if (this.state.currentId === e.itemIndex) {
                this.setState({selectedId: e.itemIndex, expanded: !this.state.expanded});
            } else {
                this.setState({ currentId: e.itemIndex });
            }            
        }

        if (mvRoute.localeCompare("/logout") === 0 ){
            logger.debug("handleSelect | 2.0")
            this.props.ampSignOut();
        } else {
            this.props.history.push(e.itemTarget.props.route);
        }
    }

    getSelectedItem = (pathName: string | undefined) => {
        logger.debug("getSelectedItem | 1.0")
        let currentPath = items.find(item => item.route === pathName);
        logger.debug("getSelectedItem | 1.5 - " + currentPath?.route )
        if (currentPath?.name) {
            logger.debug("getSelectedItem | 2.0 - " + currentPath?.name )
            return currentPath.name;
        }
    }

    componentDidUpdate() {
        logger.debug("componentDidUpdate | 1.0");
        try {       
            const parent = window.parent;
            if(parent) {
                parent.postMessage({ url: this.props.location.pathname, demo: true }, "*")
            }
        } catch(err) {
            console.warn('Cannot access iframe')
        }

    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeWindow, false)
        this.resizeWindow();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeWindow)
    }

    render() {
        logger.debug("render | 1.0");
        logger.debug("render | 1.1 - " + this.props.location);
        
        let selected:string = this.getSelectedItem(this.props.location.pathname)!;
        const localizationService = provideLocalizationService(this);

        return (
            
            <React.Fragment >
                <Header
                    onButtonClick={this.handleClick}
                    page={localizationService.toLanguageString(`custom.${selected}`, selected)}
                />
                <Drawer
                    expanded={this.state.expanded}
                    animation={{duration: 100}}
                    items={items.map((item) => ({
                                ...item,
                                text: localizationService.toLanguageString(`custom.${item.name}`, item.name!),
                                selected: item.name === selected
                            }))
                    }
                    position='start'
                    mode={this.state.isSmallerScreen ? 'overlay' : 'push'}
                    mini={this.state.isSmallerScreen ? false : true}

                    onOverlayClick={this.handleClick}
                    onSelect={this.handleSelect}
                >
                    <DrawerContent>
                        {this.props.children}
                    </DrawerContent>
                </Drawer>
            </React.Fragment>
        );
    }
};

registerForLocalization(DrawerRouterContainerAdmin);

export default withRouter(DrawerRouterContainerAdmin);
