import * as React from 'react';
import { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from './../UserContext';
import { Logger } from 'aws-amplify';
import { Grid as KGrid, GridColumn, GridSortChangeEvent, getSelectedState, GridSelectionChangeEvent, GridRowClickEvent, GridExpandChangeEvent} from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";

import Encounter from '../classes/Encounter';
import { EncounterTableRowDetailComponent } from './EncounterTableRowDetailComponent';

const logger = new Logger('EncountersTable', 'INFO');
const DATA_ITEM_KEY = "ID"; //Encounter ID
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

//decided not to use for now, but keep in case
const gridColumnsLarge = [
    {
      field: 'eDate',
      title: 'Date',
      format: '{0:E, MMM d, y}',
      show: true,
    },
    {
        field: 'eTime',
        title: 'Time',
        format: '{0:HH:mm}',  
        show: true,  
    },
    {
        field: 'loc2',
        title: 'Location',
        show: true,
    },
    {
        field: 'eType',
        title: 'Type',
        show: true,
    },
    {
        field: 'eFollowUpStr',
        title: 'Follow-Up',
        show: true,
    },
    {
        field: 'followUp.isCompleteStr',
        title: 'Follow-Up Complete',
        show: true,
    },
  ]

const gridColumnsSmall = [
{
    field: 'eDate',
    title: 'Date',
    format: '{0:E, MMM d, y}',
    show: true,
},
{
    field: 'eTime',
    title: 'Time',
    format: '{0:HH:mm}',  
    show: true,  
},
{
    field: 'loc2',
    title: 'Location',
    show: true,
},
]

interface IEncountersTableProps {
    encDataProp: Encounter[];
}

const EncountersTable = ({ encDataProp } : IEncountersTableProps) => {

    const componentIsMounted = useRef(true);
    const user = useContext(UserContext);
    //const [encData, setEncData] = useState<Encounter[]>(encDataProp); //All Encounters
    const [encData, setEncData] = useState<Encounter[]>(encDataProp.map((dataItem: Encounter) =>
            Object.assign({ selected: false}, dataItem)
    )); //All Encounters
    const [selectedState, setSelectedState] = React.useState<{[id: string]: boolean | number[];}>({});    
    //window size change state - min width est by tailwind
    const [isDesktop, setDesktop] = useState(window.innerWidth > 639);
 
    const updateMedia = () => {
        setDesktop(window.innerWidth > 639);
      };

    useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
    });      
    
    const expandChange = (event: GridExpandChangeEvent) => {
        let newData = encData.map((item: Encounter) => {
          if (item.ID === event.dataItem.ID) {
            item.expanded = !event.dataItem.expanded;
          }
          return item;
        });
        setEncData(newData);
      };

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        logger.debug("onSelectionChange | 1.0 " + JSON.stringify(selectedState));
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
    };

    const [sort, setSort] = React.useState<Array<SortDescriptor>>([
        { field: "eDate", dir: "desc" },
      ]);

    const sortChange = (event: GridSortChangeEvent) => {
        setEncData(getProducts(event.sort));
        setSort(event.sort);
    };

    const handleRowClick = (event: GridRowClickEvent) => {
        logger.debug("handleRowClick | 1.0");
        logger.debug("handleRowClick | 1.1 - event.dataItem - " + JSON.stringify(event.dataItem));
    };

    const getProducts = (sort: SortDescriptor[]): Encounter[] => {
        return orderBy(encData, sort);
        };

    useEffect(() => {
        logger.debug("useEffect | 1.0");
        if (encDataProp) {
            logger.debug("useEffect | 1.1");
            setEncData(encDataProp);
        }
        }, [encDataProp])        

    useEffect(() => {
        // each useEffect can return a cleanup function
        return () => {
            componentIsMounted.current = false;
        };
    }, []); // no extra deps => the cleanup function run this on component unmount

    useEffect(() => {
        async function queryEncounters() {
            try {
                logger.debug("queryEncounters | 1.0 - aUserID - " + user.email);

                if (componentIsMounted.current) {
                    logger.debug("queryEncounters | 2.0 - componentIsMounted.current - " + componentIsMounted.current);  
                }
            } catch (err) {
                console.error(err);
                logger.debug("queryEncounters | 5.0 - err - " + err);
        }
    }    
    queryEncounters();
    }, []);          

    return (

        <KGrid 
            style={{ height: "400px", fontSize: "12", marginTop:"11px" }} 
            data={encData.map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
              }))}
            sort={sort} 
            sortable={true} 
            resizable={true} 
            onSortChange={sortChange}
            selectable={{
                enabled: true
              }}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD} 
            onSelectionChange={onSelectionChange} 
            onRowClick={handleRowClick}
            detail={EncounterTableRowDetailComponent}
            expandField="expanded"
            onExpandChange={expandChange}
        >

            {isDesktop &&
            gridColumnsLarge.map((column, index) => {
            return column.show && <GridColumn key={index} field={column.field} title={column.title} format={column.format}/>
            })
            }
            {!isDesktop &&
            gridColumnsSmall.map((column, index) => {
            return column.show && <GridColumn key={index} field={column.field} title={column.title} format={column.format}/>
            })
            }

        </KGrid>   

    )

}

export default EncountersTable;