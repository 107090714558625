import * as React from 'react';
import { useContext, useState, useEffect, useRef, useReducer } from "react";
import { UserContext } from './../UserContext';
import { Logger } from 'aws-amplify';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import { Loader } from "@progress/kendo-react-indicators";
import {queryEncountersForUser, queryEncountersAdmin} from '../utils/DBHelper';
import Encounter from '../classes/Encounter';
import FollowUp from '../classes/FollowUp';
import {getNumberOfDays} from '../utils/DateHelper';
import EncountersAdminTable from '../components/EncountersAdminTable';
import FollowUpCards from '../components/FollowUpsCards';
import NNLoader from '../components/NNLoader';

const logger = new Logger('Dashboard', 'INFO');

/*
    If we want to use React.FC which inherently has children props, otherwise add it in your 'MyProps' interface: children?: React.ReactNode
    const MainLanding: React.FC<MyProps> = (props) => {
*/
interface IDashboardProps {
}

const Dashboard = ({ } : IDashboardProps) => {

    const [encWFUData, setEncWFUData] = useState<Encounter[]>([]); //bad name - this is Encounters with FollowUps
    const [encData, setEncData] = useState<Encounter[]>([]); //All Encounters
    const componentIsMounted = useRef(true);
    const [isLoading, setIsLoading] = useState(false);

    const user = useContext(UserContext);    

    useEffect(() => {
        // each useEffect can return a cleanup function
        return () => {
          componentIsMounted.current = false;
        };
    }, []); // no extra deps => the cleanup function run this on component unmount

    useEffect(() => {
        queryEncounters();
    }, []);  
    
    async function queryEncounters() {
        try {
        logger.debug("queryEncounters | 1.0 - aUserID - " + user.email);
        setIsLoading(true);
        const asyncResponse = await queryEncountersAdmin();
        logger.debug("queryEncounters | 2.0 - asyncResponse - " + JSON.stringify(asyncResponse));

        //iterate through Enc objects to find ones that have FollowUps
        const arrLen = asyncResponse.length;
        logger.debug("queryEncounters | 3.0 - arrLen - " + arrLen);
        let arrEncounterWithFollowUp = new Array();
        for (let i = 0; i < arrLen; i++) {
            if (asyncResponse[i].eFollowUp === true && asyncResponse[i].followUp.isComplete == false ) {
                logger.debug("queryEncounters | 3.5 - asyncResponse[i] - " + JSON.stringify(asyncResponse[i]));
                //set theme color for FollowUp avatar
                const encounter_copy = Object.assign({}, asyncResponse[i]);

                //Assuming FU date is still ahead, today would be the start date, the end date would be FU date
                //if the FU date is past due, a negative number will be returned
                const timeLeft = getNumberOfDays(new Date(), encounter_copy.followUp.followUpDate);
                if (timeLeft <= 3) {
                    logger.debug("queryEncounters | 3.6 - timeLeft - " + timeLeft);
                    asyncResponse[i].followUp.avatarThemeColor = "error";
                }
                arrEncounterWithFollowUp.push(asyncResponse[i]); 
            }
        }
        logger.debug("queryEncounters | 4.2 - arrEncounterWithFollowUp - " + JSON.stringify(arrEncounterWithFollowUp)); 
        setIsLoading(false);
        if (componentIsMounted.current) {
            logger.debug("queryEncounters | 4.1 - componentIsMounted.current - " + componentIsMounted.current);  
            setEncData(asyncResponse);
            setEncWFUData(arrEncounterWithFollowUp);
        }
        } catch (err) {
        console.error(err);
        logger.debug("queryEncounters | 5.0 - err - " + err);
        }
    }

    return (
        
        <React.Fragment>
        {isLoading ? (
            <NNLoader/>
        ) : (
        <div>        
        <Box
        className = "box-primary" 
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            bgcolor: '#ffffff',
            overflow: 'hidden',
            borderRadius: '1px',
            fontWeight: 'bold',
            padding: '12px',
            width: '100%',
            height: '100%',
          }}        
        >
            <Grid 
            className='grid-sub-primary' 
            item xs={12}
            sx={{
                width: '100%',
                height: '100%',
                bgcolor: '#f8f8f8',
                padding: '24px',
                minWidth: 'md'
                }}                                     
            >
                <Grid >
                    <Grid item xs={12}>                 
                        <span style={{ fontSize:24, fontWeight:'bold' }}>{user.group}:&nbsp;&nbsp;{user.firstName} {user.lastName}</span>
                    </Grid>
                </Grid>
                <br/>
                <Grid container wrap="nowrap" spacing={2} >
                <LocalizationProvider language={'en-US'}>
                        <IntlProvider locale={'en'} >

                    <Grid item style={{ fontSize: "20" }}>  
                        <div>
                            Encounters
                            <br/>
                           
                        </div>      
                        <EncountersAdminTable encDataProp={encData}/>
                    </Grid>
                    </IntlProvider>
                   </LocalizationProvider> 
                </Grid>
            </Grid>           
        </Box>
        <style>{`
                h1 {
                    text-align: left;
                }
                .k-grid {
                    font-size: 12px;
                }
                .photo-text {
                    font-size: 11px;
                    line-height:1px;"
                }
            `}</style>
            </div>
            )}
        </React.Fragment>
        
    )
}

export default Dashboard;

